import { api } from './request.js'

//登录
export function apiAccountLogin(data){
    return api('account/AccountLogin',data);
}

//
export function apiAccountInfo(){
    return api('account/AccountInfo');
}

//账号列表
export function apiFirmList(data){
    return api('account/FirmList',data);
}

//修改密码
export function apiAccountEditPwd(data){
    return api('account/AccountEditPwd',data);
}

//创建新账号
export function apiCreateFirm(data){
    return api('account/CreateFirm',data);
}

//跳转后台管理页面
export function apiLoginAdmin(data){
    return api('account/loginAdmin',data);
}

//获取系统版本
export function apiSystemVersion(){
    return api('account/SystemVersion');
}



//升级下载代码
export function apiSystemDownload(data){
    return api('account/SystemDownload',data);
}

//升级解压代码
export function apiSystemExtract(data){
    return api('account/SystemExtract',data);
}

//升级备份
export function apiSystemInstall(data){
    return api('account/SystemInstall',data);
}

