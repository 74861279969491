import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
axios.defaults.timeout = 200000;

axios.defaults.baseURL = "/";
//POST传参序列化

axios.interceptors.request.use((config) => {
  if(config.method  === 'post'){
    if(config.headers['Content-Type'] !== 'multipart/form-data;charset=UTF-8'){
	  config.data = config.data || {};
	  config.data.account_token = localStorage.getItem('xhmmp-account-token');			
      config.data = qs.stringify(config.data);
    }else{ //上传文件逻辑
		config.data = config.data;
		config.data.append('type','xhmmp'); //来源哪里的上传
		config.data.append('account_token',localStorage.getItem('xhmmp-account-token'));
    }
  }
  return config;
},(error) =>{
  return Promise.reject(error);
});


//返回状态判断
axios.interceptors.response.use((res) => {
	if (res.status == 200) {
		return res;
	} else {
		return Promise.reject(res);
	}
}, (error) => {
	return Promise.reject(error);
});


export function upload(url,file) {
	var datas = new FormData();
	var configs = {
		headers:{'Content-Type':'multipart/form-data;charset=UTF-8'}
	};
	datas.append('file',file);
	// xhmmp/admin/upload' platform/upload
    return axios.post(url,datas,configs)
        .then(response => {
            switch (response.data.code) {
                case 200:
                    return response.data.data;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            return Promise.reject(msg || 'error')
        });
}



export function api(url, params) {
	var configs = {
		headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
	};
	return axios.post(url, params,configs)
		.then(response => {
			switch (response.data.code) {
				case 100:
					message.error(response.data.msg);
					localStorage.removeItem('xhmmp-account-token');
					location.href = '/account/#/' + 'login';
					return Promise.reject(response.data.msg || 'error');
				case 200:
					return response.data.data;
				case 400: //400 为提示类型错误
					message.error(response.data.msg);
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});

}

export function papi(url, params) {
	var configs = {
		headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
	};
	return axios.post(url, params,configs)
		.then(response => {
			switch (response.data.code) {
				case 100:
					message.error(response.data.msg);
					localStorage.removeItem('xhmmp-account-token');
					location.href = '/account/#/' + 'login';
					return Promise.reject(response.data.msg || 'error');
				case 200:
					return response.data.data;
				case 400: //400 为提示类型错误
					message.error(response.data.msg);
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});

}
